export const STATUS_INETRAL_SECURITY_TERMINATE = 10;
export const STATUS_RECONCILE_TERMINATE = 11;
export const STATUS_INTERNAL_OVERQUOTA = 12;
export const STATUS_INTERNAL_DEMO_TERMINATE = 13;
export const STATUS_LANDING_START = 16;
export const STATUS_PROFILER_START = 17;
export const STATUS_SCREENER_START = 18;
export const STATUS_ENDING_START = 19;
export const STATUS_INTERNAL_CLIENT_REDIRECT_START = 20;
export const STATUS_SUPPLIER_REDIRECT_START = 21;

export const SUB_STATUS_LANDING_EXTERNAL_REDIRECT = 135;
export const SUB_STATUS_LANDING_EXCEPTION = 136;
export const SUB_STATUS_LANDING_INTERNAL_REDIRECT = 137;
export const SUB_STATUS_PROFILER_DISPLAY = 138;
export const SUB_STATUS_PROFILER_SERVER_ERROR = 139;
export const SUB_STATUS_PROFILER_SUBMIT = 140;
export const SUB_STATUS_PROFILER_SUBMIT_EXCEPTION = 141;
export const SUB_STATUS_PROFILER_TO_SCREENER = 142;

export const SUB_STATUS_SCREENER_DISPLAY = 143;
export const SUB_STATUS_SCREENER_DISPLAY_EXCEPTION = 144;
export const SUB_STATUS_SCREENER_SUBMIT = 145;
export const SUB_STATUS_SCREENER_SUBMIT_EXCEPTION = 146;
export const SUB_STATUS_SCREENER_EXTERNAL_REDIRECT = 147;
export const SUB_STATUS_SCREENER_INTERNAL_SUBMIT_REDIRECT = 148;
export const SUB_STATUS_SCREENER_INTERNAL_REDIRECT = 149;
export const SUB_STATUS_EXTERNAL_REDIRECT = 150;
export const SUB_STATUS_EXTERNAL_REDIRECT_DONE = 151;
export const SUB_STATUS_EXTERNAL_SUPPLIER_REDIRECT_DONE = 153;
