import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RespondentFlowApi from "../services/respondentFlow.api";
import LoggerService from "../services/logger.service";
import { SCREENER_ROUTE, ERROR_ROUTE } from "../constants/routes.constants";
import FinSurveyPng from "../assests/magnifying-glass-icon.gif";
import {
  SURVEY_SCREENER_FAILED_SUPPLIER_REDIRECT,
  SURVEY_SCREENER_NOT_REQUIRED,
  SURVEY_SCREENER_PASSED,
  SURVEY_SCREENING,
  SURVEY_SCREENING_FAILED_RESET_QUES,
  SURVEY_SCREENER_PASSED_CLIENT_REDIRECT,
} from "../constants/event.constants";
import { Backdrop, Button, CircularProgress, TextField } from "@mui/material";
import { BounceLoader } from "react-spinners";

const Start = () => {
  const [Loader, setLoader] = useState(true);
  const [ErrorMessage] = useState("");
  const [idx, setIdx] = useState(0);
  const [obj, setObj] = useState({});
  const [match, setMatch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorIndex, setErrorIndex] = useState("");
  const [questions, setQuestions] = useState([]);
  // const [sessionParams, setSessionParams] = useState(null);
  const [surveyId, setSurveyId] = useState(undefined);

  let navigate = useNavigate();

  let getDynamicProfiling = async () => {
    setLoader(true);
    let storedParam = JSON.parse(sessionStorage.getItem("params"));
    try {
      let reqObj = {};
      reqObj.token = storedParam.token;
      reqObj.userId = storedParam.userId;
      reqObj.paramList = storedParam.paramList;
      reqObj.newToken = storedParam.isEnding ? storedParam.isEnding : false;
      if (reqObj.newToken) {
        reqObj.allSurveys = false;
      } else {
        reqObj.allSurveys = true;
      }

      LoggerService.log(
        storedParam.token ?? "",
        storedParam.vendorToken ?? "",
        "Sending Router Request",
        JSON.stringify(reqObj),
        storedParam.campaignUid
      );

      let resp = await RespondentFlowApi.post("/v1/profiling/dynamic", reqObj);
      LoggerService.log(
        storedParam.token ?? "",
        storedParam.vendorToken ?? "",
        "Router Response received",
        JSON.stringify(resp),
        storedParam.campaignUid
      );
      // if (resp.isInternalRedirect) {
      let obj = {};
      obj.eventType = resp.eventType;
      obj.token = resp.token;
      obj.userId = resp.userId;
      obj.questionList = resp.questionList;
      //TODO:: get campaignUid from params

      if (obj.eventType == SURVEY_SCREENING) {
        LoggerService.log(
          resp.token,
          storedParam.vendorToken ?? "",
          "Router survey received(" +
            resp.surveyId +
            "), Dynamic Profiling started(partial_match)",
          JSON.stringify(resp),
          storedParam.campaignUid ?? ""
        );
        let questionText =
          resp.questionList.length > 0 ? resp.questionList[0].text : "";

        LoggerService.log(
          resp.token,
          storedParam.vendorToken ?? "",
          "Dynamic Profiling(" +
            resp.surveyId +
            ") Displaying the 1st question " +
            questionText,
          JSON.stringify(resp),
          storedParam.campaignUid ?? ""
        );
        setQuestions(resp.questionList);
        setSurveyId(resp.surveyId);
        setLoader(false);
      } else if (obj.eventType == SURVEY_SCREENER_PASSED_CLIENT_REDIRECT) {
        debugger;
        await LoggerService.log(
          resp.token,
          storedParam.vendorToken ?? "",
          "Router response received, Redirecting to client(exact_match)",
          `${resp.redirectUrl}`,
          storedParam.campaignUid ?? ""
        );

        sessionStorage.removeItem("params");
        window.location.href = `${resp.redirectUrl}`;
        setLoader(true);
      } else if (obj.eventType == SURVEY_SCREENER_FAILED_SUPPLIER_REDIRECT) {
        setLoader(true);
        await LoggerService.log(
          resp.token,
          storedParam.vendorToken ?? "",
          "Router response received, Redirecting to supplier(no_match)",
          `${resp.redirectUrl}`,
          storedParam.campaignUid ?? ""
        );
        sessionStorage.removeItem("params");
        window.location.href = `${resp.redirectUrl}`;
      }
    } catch (e) {
      sessionStorage.removeItem("params");
      LoggerService.log(
        storedParam.token ?? "",
        "",
        "Router response received, redirecting to erro(error)" + e.message
      );
      navigate(ERROR_ROUTE);
    }
  };

  useEffect(() => {
    getDynamicProfiling();
  }, []);

  let saveRsponse = async (event) => {
    let params = sessionStorage.getItem("params");
    let paramObj = JSON.parse(params);
    event.preventDefault();
    if (errorIndex === "") {
      setIsLoading(true);
      let reqObj = {};
      reqObj.userId = paramObj.userId;
      reqObj.token = paramObj.token;
      reqObj.surveyId = surveyId;
      reqObj.newToken = paramObj.isEnding ? paramObj.isEnding : false;
      reqObj.responses = [
        {
          id: obj.id,
          answerText: obj.answerText,
          answerId: obj.answerId,
        },
      ];
      if (questions.length - 1 === idx) {
        reqObj.isLastQuestion = true;
      } else {
        reqObj.isLastQuestion = false;
      }
      let dbquestionText = obj.text ?? "";
      LoggerService.log(
        paramObj.token ?? "",
        paramObj.vendorToken ?? "",
        `Saving ${idx + 1}  dynamic profiling question - ${dbquestionText}`,
        JSON.stringify(reqObj),
        paramObj.campaignUid ?? ""
      );

      try {
        let response = await RespondentFlowApi.post(
          `/v1/profiling/d/response`,
          reqObj
        );

        if (response.status === false) {
          LoggerService.log(
            response.token,
            paramObj.vendorToken ?? "",
            "Qualification terminated",
            JSON.stringify(response),
            paramObj.campaignUid ?? ""
          );
          if (response.eventType === SURVEY_SCREENING) {
            LoggerService.log(
              response.token,
              paramObj.vendorToken ?? "",
              "New Router survey " +
                response.surveyId +
                " received, Dynamic Profiling started(partial_match)",
              JSON.stringify(response),
              paramObj.campaignUid ?? ""
            );

            let questionText =
              response.questionList.length > 0
                ? response.questionList[0].text
                : "";
            //
            LoggerService.log(
              response.token,
              paramObj.vendorToken ?? "",
              "Dynamic Profiling(" +
                response.surveyId +
                ") Displaying the 1st question" +
                questionText,
              JSON.stringify(response),
              paramObj.campaignUid ?? ""
            );
            setQuestions(response.questionList);
            setSurveyId(response.surveyId);
            setIdx(0);
            setErrorIndex("");
            setMatch(true);
            setIsLoading(false);
          } else if (
            response.eventType === SURVEY_SCREENER_FAILED_SUPPLIER_REDIRECT
          ) {
            setIsLoading(true);
            LoggerService.log(
              response.token,
              paramObj.vendorToken ?? "",
              "Dynamic Profiling(no_survey), Redirecting to supplier(no_match)",
              `${response.redirectUrl}`,
              paramObj.campaignUid ?? ""
            );
            sessionStorage.removeItem("params");
            window.location.href = `${response.redirectUrl}`;
          }
        } else {
          if (response.eventType === SURVEY_SCREENING) {
            // is Last question

            if (questions.length - 1 === idx) {
            } else {
              let questionText = questions[idx + 1]?.text ?? "";
              LoggerService.log(
                response.token,
                paramObj.vendorToken ?? "",
                `Dynamic Profiling(${reqObj.surveyId}) Displaying the ${
                  idx + 1
                } question - ${questionText}`,
                JSON.stringify(response),
                paramObj.campaignUid ?? ""
              );
              setErrorIndex("");
              setIdx(idx + 1);
              setMatch(true);
              setIsLoading(false);
            }
          } else if (
            response.eventType === SURVEY_SCREENER_PASSED_CLIENT_REDIRECT
          ) {
            LoggerService.log(
              response.token,
              paramObj.vendorToken ?? "",
              "Dynamic Profiling (" +
                response.surveyId +
                ") Completed, redirecting to client(partial_match)",
              `${response.redirectUrl}`,
              paramObj.campaignUid ?? ""
            );
            window.location.href = `${response.redirectUrl}`;
          }
        }
      } catch (e) {
        console.error(e);
        LoggerService.log(
          paramObj.token ?? "",
          paramObj.vendorToken ?? "",
          "error in saving response " + JSON.stringify(e),
          "",
          paramObj.campaignUid ?? ""
        );
        setIsLoading(false);
        navigate(ERROR_ROUTE);
      }
    }
  };

  useEffect(() => {
    if (questions.length > 0 && questions[idx]) {
      setObj(JSON.parse(JSON.stringify(questions[idx])));
    }
    // eslint-disable-next-line
  }, [questions, idx]);

  useEffect(() => {
    if (obj.type === 3 || obj.type === 4) {
      if (
        !obj?.answerText ||
        obj?.answerText === null ||
        obj?.answerText === "" ||
        (obj.id === 59 && obj?.answerText > 99) ||
        (obj.id === 59 && obj?.answerText < 18)
      ) {
        setErrorIndex(obj.id);
      } else {
        setErrorIndex("");
      }
    } else {
      if (!obj?.answerId || obj?.answerId?.length === 0) {
        setErrorIndex(obj.id);
      } else {
        setErrorIndex("");
      }
    }

    // eslint-disable-next-line
  }, [obj]);

  const responsequestions = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {Loader ? (
        <>
          <div
            className="flex justify-center items-center h-screen"
            style={{
              backgroundColor: "var(--body)",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                textalign: "center",
                height: "90vh",
                width: "100vw",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              {" "}
              {/* //change here color */}
              {/* <BounceLoader
                color="rgb(233, 29, 37)"
                loading={true}
                size={60}
                textAlign="center"
                aria-label="Loading Spinner"
                data-testid="loader"
              /> */}
              <img height="200px" width="200px" src={FinSurveyPng} alt="" />
              {/* //change here color */}
              <span
                style={{
                  fontWeight: "700",
                  marginTop: "20px",
                  display: "block",
                  textAlign: "center",
                  color: "rgb(233, 29, 37)",
                }}
              >
                {ErrorMessage}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div
          className="h-[100vh] flex justify-center items-center p-2"
          style={{
            backgroundColor: "var(--body)",
          }}
        >
          <div className="questionCard">
            <form
              onSubmit={Loader ? responsequestions : saveRsponse}
              noValidate
              className="h-[100%] relative"
            >
              <div className="question-body ">
                <h1 className="question-text">{questions[idx]?.text}</h1>
                <div className="mb-10">
                  {(questions.length > 0 &&
                    questions[idx] &&
                    questions[idx] &&
                    questions[idx].type === 4) ||
                  (questions.length > 0 &&
                    questions[idx] &&
                    questions[idx] &&
                    questions[idx].type === 3) ? (
                    // change here
                    <>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="text"
                        className="mt-2 font-medium text-sm border-2 border-black"
                        value={obj?.answerText}
                        onChange={(e) => {
                          let payload = { ...obj };
                          payload.answerText = e.target.value;
                          setObj(JSON.parse(JSON.stringify(payload)));
                        }}
                        error={
                          !match && errorIndex === questions[idx]?.id
                            ? true
                            : false
                        }
                      />

                      {!match && errorIndex === questions[idx]?.id && (
                        <div
                          style={{
                            color: "red",
                            marginTop: "5px",
                            fontSize: "13px",
                          }}
                        >
                          {obj.answerText > 99
                            ? "This value not be greater then 100 and"
                            : obj.answerText < 18
                            ? "This value not be Less then 18"
                            : "This field is required!"}
                        </div>
                      )}
                    </>
                  ) : questions.length > 0 &&
                    questions[idx] &&
                    questions[idx].type === 1 ? (
                    <>
                      {!match && errorIndex === questions[idx]?.id && (
                        <div
                          style={{
                            color: "red",
                            marginTop: "5px",
                            fontSize: "13px",
                          }}
                        >
                          This field is required!
                        </div>
                      )}
                      <div
                        className={
                          questions[idx]?.options.length > 12
                            ? "grid md:grid-cols-2 gap-4 grid-cols-1 mt-2"
                            : "grid md:grid-cols-1 gap-4 grid-cols-1 mt-2"
                        }
                      >
                        {questions.length > 0 &&
                          questions[idx].options.map((item, i) => {
                            return (
                              <div
                                className="flex  space-x-3 items-center"
                                key={i}
                              >
                                <input
                                  name={obj?.id}
                                  type="radio"
                                  style={{ accentColor: "var(--primary)" }}
                                  id={`radio_${item.id}`}
                                  checked={obj?.answerId?.includes(item.id)}
                                  onChange={(e, value) => {
                                    let payload = { ...obj };
                                    if (e.target.checked) {
                                      payload.answerId = [item.id];
                                    } else {
                                    }
                                    setObj(JSON.parse(JSON.stringify(payload)));
                                  }}
                                />
                                <label
                                  className="text-sm font-medium cursor-pointer"
                                  htmlFor={`radio_${item.id}`}
                                >
                                  {item.text}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    <>
                      {!match && errorIndex === questions[idx]?.id && (
                        <div
                          style={{
                            color: "red",
                            marginTop: "5px",
                            fontSize: "13px",
                          }}
                        >
                          This field is required!
                        </div>
                      )}

                      <div
                        className={
                          questions[idx]?.options.length > 12
                            ? "grid md:grid-cols-2 gap-4 grid-cols-1 mt-2"
                            : "grid md:grid-cols-1 gap-4 grid-cols-1 mt-2"
                        }
                      >
                        {questions.length > 0 &&
                          questions[idx] &&
                          questions[idx].options.map((item, id) => {
                            return (
                              <div
                                className="flex  space-x-3 items-center"
                                key={id}
                              >
                                <input
                                  id={`checkbox_${item.id}`}
                                  name={obj?.id}
                                  type="checkbox"
                                  style={{ accentColor: "var(--primary)" }}
                                  checked={obj?.answerId?.includes(item.id)}
                                  onChange={(e, value) => {
                                    let payload = { ...obj };
                                    if (e.target.checked) {
                                      payload.answerId = [item.id];
                                    } else {
                                    }
                                    setObj(JSON.parse(JSON.stringify(payload)));
                                  }}
                                />
                                <label
                                  className="font-medium text-sm cursor-pointer"
                                  htmlFor={`checkbox_${item.id}`}
                                >
                                  {item.text}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-row-reverse w-full md:w-[100%] px-4 gap-5 h-fit	pb-2 bg-white absolute bottom-0">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    setMatch(false);
                  }}
                >
                  Next
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Start;
