import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/ClipLoader";
import RespondentFlowApi from "../services/respondentFlow.api";
import "./question.css";
import { updateStatus } from "../services/respondent.api";
import * as StatusConstants from "../constants/status.constants";
import { Button, TextField } from "@mui/material";
import { SCREENER_ROUTE, ERROR_ROUTE } from "../constants/routes.constants";
import LoggerService from "../services/logger.service";

const QuesCard = () => {
  const [Loader, setLoader] = useState(true);
  const [idx, setIdx] = useState(0);
  const [data, setData] = useState([]);
  const [obj, setObj] = useState({});
  let navigate = useNavigate();
  const [errorIndex, setErrorIndex] = useState("");
  const [match, setMatch] = useState(true);

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line
  }, []);

  let getQuestions = () => {
    setLoader(true);

    let params = JSON.parse(sessionStorage.getItem("params"));
    LoggerService.log(
      params.token,
      params.vendorToken ?? "",
      "Getting survey questions for screener",
      JSON.stringify(params),
      params.campaignUid ?? ""
    );
    RespondentFlowApi.post("/screener/v1/questions", params)
      .then(async (response) => {
        if (response.result === "not_ok") {
          LoggerService.log(
            params.token,
            params.vendorToken ?? "",
            "Error on getting survey questions",
            JSON.stringify(response),
            params.campaignUid ?? ""
          );
          updateStatus(
            params.token,
            params.tokenId,
            StatusConstants.STATUS_SCREENER_START,
            StatusConstants.SUB_STATUS_SCREENER_DISPLAY_EXCEPTION
          );
          navigate(`${ERROR_ROUTE}?token=${params.token}`);
          return;
        }
        if (response.questionList.length === 0) {
          LoggerService.log(
            params.token,
            params.vendorToken ?? "",
            "No screener questions found, checking status",
            JSON.stringify(response),
            params.campaignUid ?? ""
          );
          let statusObj = { status: response.status };
          await handleStatusUpdate(statusObj);
        } else {
          let params = JSON.parse(sessionStorage.getItem("params"));
          let respData = response.questionList.map((value) => {
            if (value.type === 1 || value.type === 2) {
              value.answerId = [];
            } else {
              value.answerText = null;
            }
            return value;
          });
          LoggerService.log(
            params.token,
            params.vendorToken ?? "",
            "Displaying 1st screener question",
            params.campaignUid ?? ""
          );

          updateStatus(
            params.token,
            params.tokenId,
            StatusConstants.STATUS_SCREENER_START,
            StatusConstants.SUB_STATUS_SCREENER_DISPLAY
          );
          setLoader(false);
          setData(respData);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoader(false);
        navigate(ERROR_ROUTE);
      });
  };

  let handleStatusUpdate = async (resp) => {
    setLoader(true);
    let rparams = JSON.parse(sessionStorage.getItem("params"));
    let rObj = {
      traceId: rparams.traceId,
      token: rparams.token,
      tokenId: rparams.tokenId,
      status: resp.status,
      surveyUid: rparams.surveyUid,
      vendorUid: rparams.vendorUid,
    };

    rparams["status"] = resp.status;
    RespondentFlowApi.post("/surveyLanding/v1/update/status", rparams)
      .then(async (resp) => {
        if (resp.result === "not_ok") {
          navigate(`${ERROR_ROUTE}?token=${rparams.token}`);
          return;
        }
        if (resp.isInternalRedirect) {
          LoggerService.log(
            rparams.token,
            rparams.vendorToken ?? "",
            "New survey received, redirecting to internal survey"
          );
          let obj = {};
          obj.traceId = resp.traceId;
          obj.paramList = resp.paramList;
          obj.sessionUid = resp.sessionUid;
          obj.surveyUid = resp.surveyUid;
          obj.vendorUid = resp.vendorUid;
          obj.token = resp.token;
          obj.tokenId = resp.tokenId;
          obj.userUid = resp.userUid;
          obj.userId = resp.userId;
          sessionStorage.removeItem("params");
          sessionStorage.setItem("params", JSON.stringify(obj));
          window.location.href = `${SCREENER_ROUTE}?token=${resp.token}`;
        } else {
          sessionStorage.removeItem("params");
          if (resp.redirectType === 3) {
            LoggerService.log(
              rparams.token,
              rparams.vendorToken ?? "",
              "Supplier redirect received",
              `${resp.redirectUrl}`
            );
            await updateStatus(
              rObj.token,
              rObj.tokenId,
              StatusConstants.STATUS_SUPPLIER_REDIRECT_START,
              StatusConstants.SUB_STATUS_EXTERNAL_SUPPLIER_REDIRECT_DONE
            );
          } else if (resp.redirectType === 4) {
            LoggerService.log(
              rparams.token,
              rparams.vendorToken ?? "",
              "Client survey redirect received",
              `${resp.redirectUrl}`
            );
            await updateStatus(
              rObj.token,
              rObj.tokenId,
              StatusConstants.STATUS_INTERNAL_CLIENT_REDIRECT_START,
              StatusConstants.SUB_STATUS_EXTERNAL_REDIRECT_DONE
            );
          }
          window.location.href = `${resp.redirectUrl}`;
        }
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
        navigate(ERROR_ROUTE);
      });
  };

  let postAnswer = async (event) => {
    event.preventDefault();
    if (errorIndex === "") {
      let reqObj = {};
      let params = JSON.parse(sessionStorage.getItem("params"));
      reqObj.traceId = params.traceId;
      reqObj.surveyId = params.surveyId;
      reqObj.userId = params.userId;
      reqObj.token = params.token;
      reqObj.tokenId = params.tokenId;
      reqObj.tokenId = params.tokenId;
      reqObj.responses = [
        {
          questionId: obj.id,
          answerText: obj.answerText,
          answerId: obj.answerId,
        },
      ];
      setLoader(true);
      RespondentFlowApi.post("/screener/v1/response", reqObj)
        .then(async (resp) => {
          setLoader(false);
          setObj({});
          if (resp.result === "not_ok") {
            navigate(`${ERROR_ROUTE}?token=${params.token}`);
            return;
          }
          if (resp.status === 13) {
            await handleStatusUpdate(resp);
          } else if (data.length === idx + 1) {
            let rObj = {
              traceId: params.traceId,
              token: params.token,
              tokenId: params.tokenId,
              status: resp.status,
              surveyUid: params.surveyUid,
              vendorUid: params.vendorUid,
            };

            params["status"] = resp.status;

            setLoader(true);
            RespondentFlowApi.post("/surveyLanding/v1/update/status", params)
              .then(async (resp) => {
                if (resp.result === "not_ok") {
                  navigate(`${ERROR_ROUTE}?token=${params.token}`);
                  return;
                }
                if (resp.isInternalRedirect) {
                  setLoader(false);
                  let obj = {};
                  obj.traceId = resp.traceId;
                  obj.paramList = resp.paramList;
                  obj.sessionUid = resp.sessionUid;
                  obj.surveyUid = resp.surveyUid;
                  obj.vendorUid = resp.vendorUid;
                  obj.token = resp.token;
                  obj.tokenId = resp.tokenId;
                  obj.userUid = resp.userUid;
                  obj.userId = resp.userId;
                  sessionStorage.clear();
                  sessionStorage.setItem("params", JSON.stringify(obj));

                  window.location.href = `${SCREENER_ROUTE}?token=${resp.token}`;
                } else {
                  sessionStorage.clear();
                  if (resp.redirectType === 3) {
                    // await updateStatus(
                    //   rObj.token,
                    //   StatusConstants.STATUS_SUPPLIER_REDIRECT_START,
                    //   StatusConstants.SUB_STATUS_EXTERNAL_REDIRECT_DONE
                    // );
                  } else if (resp.redirectType === 4) {
                    await updateStatus(
                      rObj.token,
                      rObj.tokenId,
                      StatusConstants.STATUS_INTERNAL_CLIENT_REDIRECT_START,
                      StatusConstants.SUB_STATUS_EXTERNAL_REDIRECT_DONE
                    );
                  }
                  window.location.href = `${resp.redirectUrl}`;
                }
              })
              .catch((err) => {
                setLoader(false);
                console.error(err);
                navigate(ERROR_ROUTE);
              });
          } else {
            setIdx(idx + 1);
          }
        })
        .catch((e) => {
          setLoader(false);
          console.error(e);
          navigate(ERROR_ROUTE);
        });
    }
  };
  useEffect(() => {
    if (data.length > 0 && data[idx]) {
      setObj(JSON.parse(JSON.stringify(data[idx])));
    }
    // eslint-disable-next-line
  }, [data, idx]);

  useEffect(() => {
    if (
      obj?.answerId?.length === 0 ||
      obj?.answerText === null ||
      obj?.answerText === "" ||
      (obj.id === 59 && obj?.answerText > 99) ||
      (obj.id === 59 && obj?.answerText < 18)
    ) {
      setErrorIndex(obj.id);
    } else {
      setErrorIndex("");
    }
    // eslint-disable-next-line
  }, [obj]);

  const responseData = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {Loader ? (
        <>
          <div
            className="flex justify-center items-center h-screen "
            style={{
              backgroundColor: "var(--body)",
            }}
          >
            <div>
              <BounceLoader
                color="var(--primary)"
                loading={true}
                size={60}
                textalign="center"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <span
                style={{
                  fontWeight: "700",
                  marginTop: "20px",
                  display: "block",
                  textalign: "center",
                  color: "var(--primary)",
                }}
              >
                {"Loading..."}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className="questionCard">
          <form
            onSubmit={Loader ? responseData : postAnswer}
            noValidate
            className="h-[100%] relative"
          >
            <div className="question-body ">
              <h1 className="question-text">{data[idx]?.text}</h1>
              <div className="mb-10">
                {(data.length > 0 &&
                  data[idx] &&
                  data[idx] &&
                  data[idx].type === 4) ||
                (data.length > 0 &&
                  data[idx] &&
                  data[idx] &&
                  data[idx].type === 3) ? (
                  // change here
                  <>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      className="mt-2 font-medium text-sm border-2 border-black"
                      value={obj?.answerText}
                      onChange={(e) => {
                        let payload = { ...obj };
                        payload.answerText = e.target.value;
                        setObj(JSON.parse(JSON.stringify(payload)));
                      }}
                      error={
                        !match && errorIndex === data[idx]?.id ? true : false
                      }
                    />

                    {!match && errorIndex === data[idx]?.id && (
                      <div
                        style={{
                          color: "red",
                          marginTop: "5px",
                          fontSize: "13px",
                        }}
                      >
                        {obj.answerText > 99
                          ? "This value not be greater then 100 and"
                          : obj.answerText < 18
                          ? "This value not be Less then 18"
                          : "This field is required!"}
                      </div>
                    )}
                  </>
                ) : data.length > 0 && data[idx] && data[idx].type === 1 ? (
                  <>
                    {!match && errorIndex === data[idx]?.id && (
                      <div
                        style={{
                          color: "red",
                          marginTop: "5px",
                          fontSize: "13px",
                        }}
                      >
                        This field is required!
                      </div>
                    )}
                    <div
                      className={
                        data[idx]?.options.length > 12
                          ? "grid md:grid-cols-2 gap-4 grid-cols-1 mt-2"
                          : "grid md:grid-cols-1 gap-4 grid-cols-1 mt-2"
                      }
                    >
                      {data.length > 0 &&
                        data[idx].options.map((item, i) => {
                          return (
                            <div
                              className="flex  space-x-3 items-center"
                              key={i}
                            >
                              <input
                                name={obj?.id}
                                type="radio"
                                style={{ accentColor: "var(--primary)" }}
                                id={`radio_${item.id}`}
                                checked={obj?.answerId?.includes(item.id)}
                                onChange={(e, value) => {
                                  let payload = { ...obj };
                                  if (e.target.checked) {
                                    payload.answerId = [item.id];
                                  } else {
                                  }
                                  setObj(JSON.parse(JSON.stringify(payload)));
                                }}
                              />
                              <label
                                className="text-sm font-medium cursor-pointer"
                                htmlFor={`radio_${item.id}`}
                              >
                                {item.text}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <>
                    {!match && errorIndex === data[idx]?.id && (
                      <div
                        style={{
                          color: "red",
                          marginTop: "5px",
                          fontSize: "13px",
                        }}
                      >
                        This field is required!
                      </div>
                    )}

                    <div
                      className={
                        data[idx]?.options.length > 12
                          ? "grid md:grid-cols-2 gap-4 grid-cols-1 mt-2"
                          : "grid md:grid-cols-1 gap-4 grid-cols-1 mt-2"
                      }
                    >
                      {data.length > 0 &&
                        data[idx] &&
                        data[idx].options.map((item, id) => {
                          return (
                            <div
                              className="flex  space-x-3 items-center"
                              key={id}
                            >
                              <input
                                id={`checkbox_${item.id}`}
                                name={obj?.id}
                                type="checkbox"
                                style={{ accentColor: "var(--primary)" }}
                                checked={obj?.answerId?.includes(item.id)}
                                onChange={(e, value) => {
                                  let payload = { ...obj };
                                  if (!e.target.checked) {
                                    payload.answerId = payload.answerId.filter(
                                      (answer) => answer !== item.id
                                    );
                                  } else {
                                    payload.answerId.push(item.id);
                                  }
                                  setObj(JSON.parse(JSON.stringify(payload)));
                                }}
                              />
                              <label
                                className="font-medium text-sm cursor-pointer"
                                htmlFor={`checkbox_${item.id}`}
                              >
                                {item.text}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-row-reverse w-full md:w-[100%] px-4 gap-5 h-fit	pb-2 bg-white absolute bottom-0">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => {
                  setMatch(false);
                }}
              >
                Next
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default QuesCard;
