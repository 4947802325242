import React from "react";
import QuesCard from "../components/QuesCard";
import "../components/question.css";
const ScreenQuestion = () => {
  return (
    <div
      className="h-[100vh] flex justify-center items-center p-2"
      style={{
        backgroundColor: "var(--body)",
      }}
    >
      <QuesCard />
    </div>
  );
};

export default ScreenQuestion;
