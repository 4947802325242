import React from "react";
import ThanksPng from "../assests/thank_you.png";
import "../App.css";

const SecurityTerminate = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textalign: "center",
        height: "90vh",
        width: "100vw",
        fontSize: "20px",
        fontWeight: "600",
      }}
    >
      <img src={ThanksPng} alt="" className="image" />
      Thanks for taking survey.
    </div>
  );
};

export default SecurityTerminate;
